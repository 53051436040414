.drawer {
    width: 60px;
    height: 100vh;
    transition: width 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    position: relative;
  }
  
  .drawer.open {
    width: 240px;
  }
  
  .toggle-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 40px;
  }
  
  .drawer-menu {
    list-style: none;
    padding: 0;
    width: 100%;
  }
  
  .drawer-menu li {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .drawer-menu li:hover {
    background-color: #909090;
  }
  
  .icon {
    font-size: 24px;
    width: 30px;
    text-align: center;
  }
  
  .label {
    margin-left: 10px;
    font-size: 16px;
  }