.view {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 90vh;
    padding: 10px 20px;
}

.view_paragraph {
    display: flex;
    justify-content: flex-start;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th {
    padding: 8px;
    text-align: center;
    color: #ffffff;
    border: 1px solid #ddd;
}

td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
    font-weight: 900;
}

tr:hover {
    background-color: #FF856b !important;
}

.actionButton {
    cursor: pointer;
}

.icon_view {
    color: #00FF00;
}

.icon_edit {
    color: #0000FF;
}

.icon_delete {
    color: #FF0000;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pageButton {
    margin: 0 5px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 4px;
}